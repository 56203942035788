import React, { useEffect, useRef, useState } from "react"
import Helmet from 'react-helmet'
import { StaticImage } from "gatsby-plugin-image"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import SVGRiseLogo from '../images/rise-logo02.inline.svg'
import SVGBalloonTether from '../images/balloon-tether.inline.svg'
// import SVGFootHills from '../images/foothills2.inline.svg'
import Foothills from '../components/Foothills'
import SVGFGTrees from '../images/trees-foreground02.inline.svg'
// import SVGRiseAK from '../images/riseak-mask.inline.svg'
import gsap from 'gsap'
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { Spin as Hamburger } from 'hamburger-react'
import Slider from 'react-slick'
import '../scss/styles.scss'
import '../scss/slick/slick.scss'
import '../scss/slick/slick-theme.scss'
import Modal from 'react-modal'

// Register GSAP ScrollTrigger
gsap.registerPlugin(ScrollTrigger); 
gsap.registerPlugin(ScrollToPlugin); 

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby')


const IndexPage = () => {
  const [isOpen, setOpen] = useState(false)
  const refLogo = useRef(null)
  const refTether = useRef(null)
  const refCloud01 = useRef(null)
  const refCloud02 = useRef(null)
  const refCloud03 = useRef(null)
  const refIntro = useRef(null)
  const refPoint01 = useRef(null)
  const refPoint02 = useRef(null)
  const refPoint03 = useRef(null)
  const refPoint04 = useRef(null)
  const refPoint05 = useRef(null)
  const refScenery = useRef(null)
  const refDenali = useRef(null)
  const refHeader = useRef(null)
  const refInstagram = useRef(null)
  const refAlpenglow = useRef(null)
  const [modalIsOpen,setIsOpen] = useState(false);
  
  // Navigation list
  const links = [
    {
      text: "About",
      url: "#about",
      target: '',
      rel: '',
      trigger: '',
    },
    {
      text: "Rates & Hours",
      url: "#rates",
      target: '',
      rel: '',
      trigger: '',
    },
    {
      text: "Contact",
      url: "",
      target: '',
      rel: '',
      trigger: 'modal',
    },
    {
      text: "#RISEAK",
      url: "https://instagram.com/riseak",
      target: '_blank',
      rel: "me",
      trigger: '',
    },
  ]

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal(){
    setIsOpen(false);
  }


  const scrollToTop = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo:{
        y:0
      }
    })
  }

  // Match heights of responsive SVGs
  const calcHeights = () => {
    // Our doc height
    let dh = Math.max(
      document.documentElement["clientHeight"],
      document.body["scrollHeight"],
      document.documentElement["scrollHeight"],
      document.body["offsetHeight"],
      document.documentElement["offsetHeight"]
    )
    console.log('dh:', dh)
    
    // Our doc width
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    // Where denali should start (logo + intro + margins + height of first bullet point)
    let sceneryStart = (vw > 959 ? 500 : 250) + refIntro.current.offsetHeight + refPoint01.current.offsetHeight + refHeader.current.offsetHeight
    refScenery.current.style.paddingTop = `${sceneryStart}px`
    refAlpenglow.current.style.top = `${sceneryStart - (refAlpenglow.current.offsetHeight * .45)}px`
    // refScenery.current.style.height = `${(dh - sceneryStart) + 200}px`
    console.log(`scene height / dh: ${refScenery.current.style.height} / ${dh}`)

    //Instagram wrapper height same as variable background SVG  
    /* let igTrees = document.getElementById('SVGIGTrees')
    refInstagram.current.style.height = igTrees.clientHeight + 'px' */
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

      // Cloud motion
      gsap.set(refCloud01.current, {left: '-5%'})
      gsap.to(refCloud01.current, {
        duration: gsap.utils.random(5, 10),
        x: 0 - refCloud01.current.offsetWidth - refCloud01.current.offsetLeft,
        ease: "none", 
        onComplete: () => {
          gsap.set(refCloud01.current, { left: 0 })
          gsap.fromTo(refCloud01.current,
            { x: vw },
            {
              duration: gsap.utils.random(20, 45),
              x: 0 - refCloud01.current.offsetWidth,
              repeat: -1,
              ease: "none",
            }
          )
        }
      })

      gsap.set(refCloud02.current, {left: '30%'}) 
      gsap.to(refCloud02.current, {
        duration: gsap.utils.random(10, 30),
        x: 0 - refCloud02.current.offsetWidth - refCloud02.current.offsetLeft,
        ease: "none", 
        onComplete: () => {
          gsap.set(refCloud02.current, { left: 0 })
          gsap.fromTo(refCloud02.current,
            { x: vw },
            {
              duration: gsap.utils.random(20, 45),
              x: 0 - refCloud02.current.offsetWidth,
              repeat: -1,
              ease: "none",
            }
          )
        }
      })

      gsap.set(refCloud03.current, {left: '55%'}) 
      gsap.to(refCloud03.current, {
        duration: gsap.utils.random(10, 30),
        x: 0 - refCloud03.current.offsetWidth - refCloud03.current.offsetLeft,
        ease: "none", 
        onComplete: () => {
          gsap.set(refCloud03.current, { left: 0 })
          gsap.fromTo(refCloud03.current,
            { x: vw },
            {
              duration: gsap.utils.random(20, 45),
              x: 0 - refCloud03.current.offsetWidth,
              repeat: -1,
              ease: "none",
            }
          )
        }
      })

      // Match heights of responsive SVGs
      calcHeights()
      window.addEventListener('resize', () => {
        calcHeights()
        // Redefine this for IG scroll animation
        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        // Instagram trees
        gsap.set(refInstagram.current, {y: vw > 959 ? 800 : 300})
        gsap.to(refInstagram.current, {
          scrollTrigger: {
            trigger: refInstagram.current,
            start: 'top 100%',
            end: '+=80%',
            scrub: 1,
            markers: false,
          },
          y: vw > 959 ? 0 : 0,
        })
      })

      // Logo pull up animation
      gsap.to('.riseGroup', {
        opacity: 1,
        y: 0,
        duration: 4,
        delay: 1
      })

      // Scroll animations
      // Denali
      gsap.set(refDenali.current, {y: 100})
      gsap.to(refDenali.current, {
        scrollTrigger: {
          trigger: refDenali.current,
          start: 'top 100%',
          end: '+=80%',
          scrub: 1,
          markers: false,
        },
        y: 0,
      })
      // Foothills is a component
      // Instagram trees
      gsap.set(refInstagram.current, {y: vw > 959 ? 800 : 300})
      gsap.to(refInstagram.current, {
        scrollTrigger: {
          trigger: refInstagram.current,
          start: 'top 100%',
          end: '+=80%',
          scrub: 1,
          markers: false,
        },
        y: vw > 959 ? 0 : 0,
      })
      // Points (left)
/*    const slideDur = 1
      gsap.from(refPoint01.current, {
        scrollTrigger: {
          trigger: refPoint01.current,
          start: 'top 70%',
          scrub: false,
          markers: false,
        },
        duration: slideDur,
        x: -100,
        opacity: 0,
      })
      gsap.from(refPoint03.current, {
        scrollTrigger: {
          trigger: refPoint03.current,
          start: 'top 70%',
          scrub: false,
          markers: false,
        },
        duration: slideDur,
        x: -100,
        opacity: 0,
      })
      gsap.from(refPoint05.current, {
        scrollTrigger: {
          trigger: refPoint05.current,
          start: 'top 70%',
          scrub: false,
          markers: false,
        },
        duration: slideDur,
        x: -100,
        opacity: 0,
      })
      // Points (right)
      gsap.from(refPoint02.current, {
        scrollTrigger: {
          trigger: refPoint02.current,
          start: 'top 70%',
          scrub: false,
          markers: false,
        },
        duration: slideDur,
        x: 100,
        opacity: 0,
      })
      gsap.from(refPoint04.current, {
        scrollTrigger: {
          trigger: refPoint04.current,
          start: 'top 70%',
          scrub: false,
          markers: false,
        },
        duration: slideDur,
        x: 100,
        opacity: 0,
      })
 */    
    }
  }, [])

  // Slick slider settings
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 10,
    slidesToShow: 3,
    slidesToScroll: 1,
    waitForAnimate: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <>
      <Helmet title="RISE AK" />
      <div className="scenery" ref={refScenery}>
        <div className="alpenglow" ref={refAlpenglow} />
        <div className="denali" ref={refDenali} />
        <div className="foothills">
          <Foothills />
        </div>
      </div>
      <header className="riseHeader" ref={refHeader}>
        <div className="clouds">
          <div ref={refCloud02} className="cloud02 cloudWrapper"><StaticImage src="../images/cloud02.png" loading="eager" placeholder="none" alt="cloud" /></div>
          <div ref={refCloud03} className="cloud03 cloudWrapper"><StaticImage src="../images/cloud03.png" loading="eager" placeholder="none" alt="cloud" /></div>
          <div ref={refCloud01} className="cloud01 cloudWrapper"><StaticImage src="../images/cloud01.png" loading="eager" placeholder="none" alt="cloud" /></div>
        </div>
        <div className="offCanvas" style={{ top: `${ isOpen ? '-2%' : '-200%'}` }}>
          <ul className="OCNavList">
            {links.map((link, i) => {
              return (
                <li key={`oc-nav-list-${i}`}>
                  <a
                    href={link.url}
                    target={link.target}
                    rel={link.rel}
                    className="navLink"
                    onClick={(e) => {
                      if (link.trigger === 'modal') {
                        e.preventDefault();
                        openModal();
                      } else {
                        setOpen(false);
                      } 
                    }}>{link.text}</a>
                </li>
              )
            })}
          </ul>
        </div>
        <nav className="riseNav">
          <ul className="navList">
            {links.map((link, i) => {
              return (
                <li key={`nav-list-${i}`}>
                  <a href={link.url} target={link.target} rel={link.rel} className="navLink" onClick={(e) => { if (link.trigger === 'modal') { e.preventDefault();openModal(); } }}>{link.text}</a>
                </li>
              )
            })}
          </ul>
          <div className="burgerMenu">
            <Hamburger toggled={isOpen} toggle={setOpen} rounded hideOutline={true} size={48} />
          </div>
          <div className="logoWrapper" ref={refLogo}>
            <div ref={refTether} style={{position: 'absolute', left: '0', top: '0', width: '100%'}}>
              <SVGBalloonTether />
            </div>
            <SVGRiseLogo />
          </div>
        </nav>
      </header>
      <main>
        <div className="container">
          <div id="about" className="intro container container-sm" ref={refIntro}>
            <p>
              Feel your heart race as you safely <strong>soar to the skies</strong> above Talkeetna.
            </p>
            <p>
              Experience the vistas of Denali in a way few have, all from the unique vantage of our
              Rise tethered balloon. You’ll be presented with unforgettable views that you won’t get
              anywhere else in Alaska.
            </p>
          </div>
          <div className="balloonPoints" id="rates">
              {/* The Views */}
              <div className="point01 bpWrapper" ref={refPoint01}>
                <div className="bpText">
                  <h3>The Views</h3>
                  <p>
                  Take your visit to new heights with an adventure like none other. Board our helium
                  balloon and float in the sky above Talkeetna, a town surrounded by landscapes in a
                  class of their own. Take in the wonder when you see the grandeur of Denali amid the
                  Alaska Range and the confluence of the Talkeetna, Chulitna and Susitna Rivers.
                  </p>
                </div>
                <div className="bpIcon">
                  <StaticImage src="../images/icon-eye.svg" alt="Camera icon" placeholder="blurred" />
                </div>
              </div>
              {/* Photography */}
              <div className="point02 bpWrapper ralign" ref={refPoint02}>
                <div className="bpText">
                  <h3>Photography</h3>
                  <p>
                    This is the perfect opportunity to snap a photo of Denali in all of her glory.
                    As we lift off, you will be surrounded by 360 degrees of beauty just begging to
                    get its picture taken. Be sure to capture the moment forever—take a selfie or
                    break out a zoom lens to see parts of Denali that you’ve never seen from the
                    ground.
                  </p>
                </div>
                <div className="bpIcon">
                  <StaticImage src="../images/icon-camera.svg" alt="Camera icon" placeholder="blurred" />
                </div>
              </div>
              {/* Information & Stuff */}
              <div className="point03 bpWrapper" ref={refPoint03}>
                <div className="bpText">
                  <h3>Where Are We?</h3>
                  <p>
                    We’re located in the iconic village of Talkeetna, Alaska. Home to a variety of
                    unique and memorable experiences, you’ll always have something awesome to do
                    here. Reservations can be made in advance, or you can stroll over to our sales
                    booth on East Main Street to get up into the air.
                  </p>
                </div>
                <div className="bpIcon">
                  <StaticImage src="../images/icon-map.svg" alt="Map icon" placeholder="blurred" />
                </div>
              </div>
              {/* Elevation & Time */}
              <div className="point04 bpWrapper ralign" ref={refPoint05}>
                <div className="bpText">
                  <h3>By the Numbers</h3>
                  <p>
                    Plan for 30 minutes from arrival to departure. Once you’re here, you’ll be briefed
                    before stepping into the gondola. The excitement builds as you rise up 300 feet in
                    elevation, towering above the treeline. You will enjoy the perfect panoramic views
                    for 12 minutes before being grounded back in reality. 
                  </p>
                </div>
                <div className="bpIcon">
                  <StaticImage src="../images/icon-updown.svg" alt="UpDown icon" placeholder="blurred" />
                </div>
              </div>
              {/* Safety */}
              <div className="point05 bpWrapper" ref={refPoint04}>
                <div className="bpText">
                  <h3>Safety</h3>
                  <p>
                    The Rise balloon is always anchored to the ground with a cable and winch system. This
                    allows us to gracefully ascend and return safely to the same location. 
                  </p>
                </div>
                <div className="bpIcon">
                  <StaticImage src="../images/icon-safety.svg" alt="Map icon" placeholder="blurred" />
                </div>
              </div>
          </div>
        </div>
        <div className="instagram">
          
          <div className="igTrees" ref={refInstagram}>
            <SVGFGTrees />
          </div>

          <div className="contentWrapper">

            <div className="container faq">

              <h2>Frequently Asked Questions</h2>

              <div className="faccordion">

                <Accordion allowZeroExpanded={true}>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                              What should I bring?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                          We recommend that you bring a light jacket with you.
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            What should I not bring?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                          Bags, strollers, food, and drink are not permitted. A secure storage area is available for 
                          guests that need to store items. Smoking in any capacity is not permitted.
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            Can I take photos while in the air?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                          Of course! For guests that wish to use a cell phone for photos or video, a universal tethered
                          phone case will be provided for your use. 

                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            How long is the experience?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                            Plan for 30 minutes from arrival to departure. You will be in the balloon for approximately
                            15 minutes, and in flight for about 12 minutes.
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            Do I have to book in advance?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                            No, you don’t have to book in advance. You can walk up to our sales booth located at (???)
                            or the actual balloon launch site located at (???) to purchase tickets. You can also purchase
                            tickets in advance to guarantee your time slot.
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            How many people can go up at once?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                            The balloon can carry up to five persons at one time, however space for four is all that is
                            bookable online at one time. The maximum number of persons at one time is based on weight,
                            and we will be there to guide you through it. If you have a group larger than 4 guests,
                            please call us to assist with your booking.
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            Are there any age restrictions?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                            Children must be 5 years of age to ride. Children under 13 years of age must be accompanied
                            by an adult 18 years of age or older.
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            How big is the balloon?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                            The balloon is 45 feet in diameter with a circumference of 140 feet.
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            How high does the balloon go?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                            You will rise up to 300 feet, giving you a perfect panoramic view of Denali and the
                            confluence of the “Three Rivers.”
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            Are there any age restrictions?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                            Children must be 5 years of age to ride. Children under 13 years of age must be accompanied
                            by an adult 18 years of age or older.
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            How big is the balloon?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                            The balloon is 45 feet in diameter with a circumference of 140 feet.
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            How high does the balloon go?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                            You will rise up to 300 feet, giving you a perfect panoramic view of Denali and the
                            confluence of the “Three Rivers.”
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            What happens if the weather is bad?
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p>
                            The balloon will not fly if wind speeds exceed XXXX, or if there is a storm or
                            lightning in the area. Please note that the balloon is tethered and will not travel
                            beyond 300 feet at any time.
                          </p>
                          <p>
                            For up-to-date flight information and weather-related availability, call us at 907-203-8299.
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>

              <div className="igCTA">
                <h3>Tag your photos <strong>#riseak</strong> and follow us</h3>
                <a href="https://instagram.com/" rel="me noreferrer" className="button" target="_blank">#RISEAK</a>
              </div>
            </div>

            <div className="igSlider" style={{position: 'relative'}}>
              <Slider { ...settings }>
                <div>
                  <StaticImage src="../images/slide01.jpg" alt="instagram" />
                </div>
                <div>
                  <StaticImage src="../images/slide02.jpg" alt="instagram" />
                </div>
                <div>
                  <StaticImage src="../images/slide03.jpg" alt="instagram" />
                </div>
                <div>
                  <StaticImage src="../images/slide01.jpg" alt="instagram" />
                </div>
                <div>
                  <StaticImage src="../images/slide02.jpg" alt="instagram" />
                </div>
              </Slider>
              <div className="riseAKMask" />
            </div>
            
            <div className="riseSocial">
              <a href="https://www.instagram.com" target="_blank" rel="me">
                <StaticImage src="../images/social-instagram.svg" width={60} alt="RISE Instagram" placeholder="blurred" />
              </a>
              <a href="https://www.facebook.com" target="_blank" rel="me">
                <StaticImage src="../images/social-facebook.svg" width={60} alt="RISE Facebook" placeholder="blurred" />
              </a>
            </div>

          </div>
        </div>
      </main>
      <div className="footer">
        <button onClick={openModal} className="button">Contact Us</button>
        <button onClick={scrollToTop} className="button">Back to Top</button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Contact Us"
        style={{
          overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            right: 'unset',
            bottom: 'unset',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            // WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: 0,
          }
        }}
      >
        <div className="modalContent">
          <h2>Contact Us</h2>
          <button onClick={closeModal} className="closeButton button">x</button>
          <div style={{marginBottom: '20px'}}>Our friendly staff will respond to any questions or requests.</div>
          <form name="Contact" method="POST" data-netlify="true" netlify>
            <div className="flexFields split">
              <input type="text" name="firstName" placeholder="First Name" required />
              <input type="text" name="lastName" placeholder="Last Name" />
            </div>
            <div className="flexFields split">
              <input type="email" name="email" placeholder="Email Address" required />
              <input type="tel" name="phone" placeholder="Phone Number" required />
            </div>
            <div className="flexFields area">
              <textarea placeholder="How can we help?" rows="6" name="message"></textarea>
            </div>
            <input type="hidden" name="form-name" value="Contact" />
            <div className="flexFields" style={{justifyContent: 'space-between'}}>
              <div></div>
              <input type="submit" className="button" />
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default IndexPage
